import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isArray } from 'lodash';
import { FaEdit } from 'react-icons/fa';
import { MdOutlineDelete, MdInfoOutline } from 'react-icons/md';
import { getAllData } from '../../../redux/actions/api/GetAllData';
import { deleteData } from '../../../redux/actions/api/DeleteData';
import ModalConfirm from '../../../components/modal/ModalConfirm';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import Loading from '../../../components/loading/Loading';
import Table from '../../../components/table/Table';
import Virtual from '../Virtual/Virtual';

export default function Courses() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const location = useLocation();
  const { accessToken } = useSelector((state) => state.auth);
  const { data: all, loading, error } = useSelector((state) => state.api);
  const [courseId, setCourseId] = useState();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const { courses } = all;
  const title_en = location?.state;
  // ----------------------------------------------------------------------------------->
  const columns = React.useMemo(
    () => [
      {
        Header: 'Image',
        accessor: 'image',
        disableFilters: true,
        Cell: ({ row }) => (
          <img
            width='40'
            height='40'
            className='rounded-circle border'
            src={row.values.image}
            alt='Images courses'
          />
        ),
      },
      {
        Header: 'Title En',
        disableFilters: true,
        accessor: 'title_en',
      },
      {
        Header: 'Title Ar',
        disableFilters: true,
        accessor: 'title_ar',
      },
      {
        Header: 'Hours',
        disableFilters: true,
        accessor: 'hours',
      },
      {
        Header: 'Start At',
        disableFilters: true,
        accessor: 'start_at',
      },
      {
        Header: 'Fees',
        disableFilters: true,
        accessor: 'fees',
      },
      {
        Header: 'Status',
        disableFilters: true,
        accessor: 'status',
      },
      {
        Header: 'Created At',
        disableFilters: true,
        accessor: 'created_at',
        Cell: ({ row }) => (
          <time>{moment(row.values.created_at).format('LLL')}</time>
        ),
      },
      {
        Header: 'Updated At',
        disableFilters: true,
        accessor: 'updated_at',
        Cell: ({ row }) => (
          <time>{moment(row.values.updated_at).format('LLL')}</time>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        disableFilters: true,
        Cell: ({ row }) => (
          <>
            <button className='btn btn-icon btn-rounded btn-primary mr-1'>
              <FaEdit
                size='1rem'
                onClick={() =>
                  navigate(
                    `/categories/${categoryId}/courses/${row.values.id}/edit`
                  )
                }
              />
            </button>
            <button className='btn btn-icon btn-rounded btn-danger mr-1'>
              <MdOutlineDelete
                size='1rem'
                onClick={() => {
                  setCourseId(row.values.id);
                  setShowModalConfirm(true);
                }}
              />
            </button>
            <button className='btn btn-icon btn-rounded btn-info'>
              <MdInfoOutline
                size='1rem'
                onClick={() =>
                  navigate(
                    `/categories/${categoryId}/courses/${row.values.id}/details`,
                    {
                      state: row.original,
                    }
                  )
                }
              />
            </button>
          </>
        ),
      },
    ],
    [categoryId, navigate]
  );
  // ----------------------------------------------------------------------------------->
  useEffect(() => {
    dispatch(
      getAllData(`course-categories/${categoryId}/courses`, accessToken)
    );
  }, [accessToken, dispatch, categoryId, isShowLoading]);
  // ----------------------------------------------------------------------------------->
  const data = useMemo(() => isArray(courses) && courses, [courses]);

  return (
    <>
      <ModalConfirm
        delete={() =>
          dispatch(
            deleteData(
              `courses/${courseId}`,
              accessToken,
              setShowModalConfirm,
              setIsShowLoading,
              isShowLoading
            )
          )
        }
        showModalConfirmTry={setShowModalConfirm}
        toggleModal={showModalConfirm}
      />
      <Breadcrumb
        url={`/categories/${categoryId}/courses/new`}
        title={`All courses by category ${title_en && title_en}`}
        textActive='Courses'
        items={[{ title: 'Categories' }]}
        btnText='New'
      />
      {loading ? (
        <Loading isLoading={loading} />
      ) : error ? (
        error
      ) : (
        <>
          <div className='card shadow mb-4'>
            {data && <Table columns={columns} data={data ?? []} />}
          </div>
          <Virtual />{' '}
        </>
      )}
    </>
  );
}
