import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import ApiReducer from '../reducers/ApiReducer';
import AuthReducer from '../reducers/AuthReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers({
    api: ApiReducer,
    auth: AuthReducer,
  }),
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
