import ApiConfig from '../../../api/ApiConfig';
import { ERROR, LOAD_DATA_SUCCESS, REMOVE_COOKIE } from '../../types';

//get all data
export const getAllData = (url, accessToken) => async (dispatch) => {
  try {
    const res = await ApiConfig.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch({
      type: LOAD_DATA_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    if (err?.response?.status === 401) {
      // status failed userUnauthorized token
      dispatch({ type: REMOVE_COOKIE });
    }
    if (err?.response?.status === 422) {
      Object.entries(err?.response?.data?.msg).forEach(([key, value]) => {
        dispatch({
          type: ERROR,
          payload: value[0],
        });
      });
    } else if (err?.response?.status === 500) {
      dispatch({
        type: ERROR,
        payload: 'server error',
      });
    }
  }
};
