import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FaLocationArrow } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { IconEye, IconEyeOff } from '@tabler/icons';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loading from '../../components/loading/Loading';
import { editData } from '../../redux/actions/api/EditData';
import { toggleShowingPassword } from '../../utils/function/toggleShowingPassword';
import { checkComplicityPassword } from '../../utils/function';

export default function ChangePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isShowPasswordCurrent, setIsShowPasswordCurrent] = useState(false);
  const [isShowPasswordNew, setIsShowPasswordNew] = useState(false);
  const [isShowPasswordConfirm, setIsShowPasswordConfirm] = useState(false);
  const { accessToken } = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.api);
  // ----------------------------------------------------------------------------------->
  useEffect(() => {
    error && toast.error(error);
  }, [dispatch, error, loading]);
  // ----------------------------------------------------------------------------------->
  const initialValues = {
    // initial Values input felids
    password: {
      current: '',
      new: '',
      confirm: '',
    },
  };
  // ----------------------------------------------------------------------------------->
  const validationSchema = Yup.object({
    password: Yup.object().shape({
      current: Yup.string()
        .required(`Please Enter your current password`)
        .min(8, `The current password you should not be less than 8 characters`)
        .max(20, `The current password you must not than 20 characters`),
      new: Yup.string()
        .required(`Please Enter your new password`)
        .min(8, `The new password you should not be less than 8 characters`)
        .max(20, `The new password you must not than 20 characters`)
        .matches(
          checkComplicityPassword,
          'New password does not meet complexity requirements'
        ),
      confirm: Yup.string()
        .required(`Please Enter your confirm password`)
        .min(8, `The confirm password you should not be less than 8 characters`)
        .max(20, `The confirm password you must not than 20 characters`)
        .oneOf([Yup.ref('new'), null], 'password confirm do not match equals'),
    }),
  });
  // ----------------------------------------------------------------------------------->
  const onSubmit = (values) => {
    // handel to modify admin
    const data = {
      old_password: values?.password?.current,
      password: values?.password?.new,
      password_confirmation: values?.password?.confirm,
    };
    dispatch(
      editData(
        `admins/${id}/update-password`,
        accessToken,
        data,
        navigate,
        '/admins'
      )
    );
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  return (
    <>
      <Loading isLoading={loading} />

      <Breadcrumb
        title='Change password admin'
        textActive='Change Password'
        items={[{ title: 'Admins' }]}
      />
      <div className='card rounded-1 mb-4 p-3'>
        <form onSubmit={formik.handleSubmit}>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='oldPassword' className='text-muted'>
                  Enter your Current password{' '}
                  <span className='required'>*</span>
                </label>
                <div className='input-group input-group-flat'>
                  <input
                    type={`${isShowPasswordCurrent ? 'text' : 'password'}`}
                    name='password.current'
                    inputMode='password'
                    id='current-password'
                    className='form-control'
                    placeholder='***************'
                    {...formik.getFieldProps('password.current')}
                  />
                  <span
                    className='input-group-text'
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      toggleShowingPassword(
                        isShowPasswordCurrent,
                        setIsShowPasswordCurrent
                      )
                    }
                  >
                    {isShowPasswordCurrent ? <IconEye /> : <IconEyeOff />}
                  </span>
                </div>
                {formik.touched.password?.current &&
                formik.errors.password?.current ? (
                  <div className='text-error'>
                    {formik.errors.password?.current}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='password' className='text-muted'>
                  Enter your new password <span className='required'>*</span>
                </label>
                <div className='input-group input-group-flat'>
                  <input
                    type={`${isShowPasswordNew ? 'text' : 'password'}`}
                    name='password.new'
                    id='new-password'
                    inputMode='text'
                    className='form-control'
                    placeholder='***************'
                    autoComplete='off'
                    {...formik.getFieldProps('password.new')}
                  />
                  <span
                    className='input-group-text'
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      toggleShowingPassword(
                        isShowPasswordNew,
                        setIsShowPasswordNew
                      )
                    }
                  >
                    {isShowPasswordNew ? <IconEye /> : <IconEyeOff />}
                  </span>
                </div>
                {formik.touched.password?.new && formik.errors.password?.new ? (
                  <div className='text-error'>
                    {formik.errors.password?.new}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='passwordConfirm' className='text-muted'>
                  Enter your confirm new password{' '}
                  <span className='required'>*</span>
                </label>
                <div className='input-group input-group-flat'>
                  <input
                    type={`${isShowPasswordConfirm ? 'text' : 'password'}`}
                    name='password.confirm'
                    id='confirm-password'
                    inputMode='text'
                    className='form-control'
                    placeholder='***************'
                    autoComplete='off'
                    {...formik.getFieldProps('password.confirm')}
                  />
                  <span
                    className='input-group-text'
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      toggleShowingPassword(
                        isShowPasswordConfirm,
                        setIsShowPasswordConfirm
                      )
                    }
                  >
                    {isShowPasswordConfirm ? <IconEye /> : <IconEyeOff />}
                  </span>
                </div>
              </div>
              {formik.touched.password?.confirm &&
              formik.errors.password?.confirm ? (
                <div className='text-error'>
                  {formik.errors.password?.confirm}
                </div>
              ) : null}
            </div>
            <div className='col-md-12 mt-3 text-center'>
              <button
                className='btn btn-primary btn-user btn-inline'
                type='submit'
              >
                <FaLocationArrow style={{ marginRight: '.5rem' }} /> Change
                Password
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
