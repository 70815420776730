import { toast } from 'react-toastify';
import ApiConfig from '../../../api/ApiConfig';
import { ERROR, PRE_REQUEST, REMOVE_COOKIE, UPDATE_DATA } from '../../types';

// edit data form api
export const editData =
  (url, accessToken, data, navigate, redirect, type = false) =>
  async (dispatch) => {
    try {
      dispatch({
        type: PRE_REQUEST,
      });
      const res = await ApiConfig({
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
        method: type ? 'POST' : 'PUT',
        url: `${url}`,
      });
      if (res.status === 200) {
        toast.success('updated successfully');
        dispatch({
          type: UPDATE_DATA,
        });
        navigate(redirect);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        // status failed userUnauthorized token
        dispatch({ type: REMOVE_COOKIE });
      } else if (err?.response?.status === 422) {
        Object.entries(err?.response?.data?.msg).forEach(([key, value]) => {
          dispatch({
            type: ERROR,
            payload: value[0],
          });
        });
      } else if (err?.response?.status === 500) {
        dispatch({
          type: ERROR,
          payload: 'server error',
        });
      } else {
        dispatch({
          type: ERROR,
          payload:
            'There is a problem that the edit item was not successful, please try again',
        });
      }
    }
  };
