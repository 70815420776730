import { useLocation } from 'react-router';
import moment from 'moment';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';

export default function DetailsContact() {
  const location = useLocation();
  const { id, name, phone, email, message, created_at, updated_at } =
    location?.state;
  return (
    <>
      <Breadcrumb
        title='Details a contact'
        textActive='Details'
        items={[{ title: 'Contacts' }]}
      />
      {Object.values(location?.state).length > 0 ? (
        <div className='container-fluid'>
          <div className='row col-11 col-md-8 m-auto details-info'>
            <div className='col-md-12'>
              <div className='row border-bottom border-end border-start'>
                <div className='col-md-4'>
                  <h3 className='title'>id :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>#{id}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-6 pt-4 border-bottom border-end border-start'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>name :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>{name}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-6 pt-4 border-bottom border-end border-start border-end-mb-0'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>email :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>{email}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-6 pt-4 border-bottom border-end border-start'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>phone :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title text-right'>{phone}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-6 pt-4 border-bottom border-end border-start border-end-mb-0'>
              <div className='row'>
                <div className='col-md-4'>
                </div>
                <div className='col-md-8'>
                </div>
              </div>
            </div>
            <div className='col-md-12 pt-4 border-bottom border-end border-start'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>message :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>{message}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-6 pt-4 border-bottom border-end border-start'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>created at :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>
                    {moment(created_at).format('LLL')}{' '}
                  </h4>
                </div>
              </div>
            </div>
            <div className='col-md-6 pt-4 border-bottom border-end border-start border-end-mb-0'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>updated at :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>
                    {moment(updated_at).format('LLL')}{' '}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='text-error text-center'>
          You have not add any data equals this id principles
        </div>
      )}
    </>
  );
}
