import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllData } from '../../redux/actions/api/GetAllData';
import { IconStar } from '@tabler/icons';
import { isArray } from 'lodash';
import Loading from '../../components/loading/Loading';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import ModalConfirm from '../../components/modal/ModalConfirm';
import { deleteVideo } from '../../redux/actions/api/deleteVideo';

export default function Videos() {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state?.auth);
  const { data, loading, error } = useSelector((state) => state?.api);
  const [videoUrl, setVideoUrl] = useState();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  // ----------------------------------------------------------------------------------->
  useEffect(() => {
    dispatch(getAllData('videos', accessToken));
  }, [accessToken, dispatch, isShowLoading]);
  return (
    <>
      <ModalConfirm
        delete={() =>
          dispatch(
            deleteVideo(
              `videos`,
              accessToken,
              setShowModalConfirm,
              setIsShowLoading,
              isShowLoading,
              videoUrl
            )
          )
        }
        showModalConfirmTry={setShowModalConfirm}
        toggleModal={showModalConfirm}
      />
      <Breadcrumb
        url='/videos/new'
        title='All videos'
        textActive='videos'
        btnText='New'
      />
      {loading ? (
        <Loading isLoading={loading} />
      ) : error ? (
        error
      ) : (
        <div className='card shadow mb-4 col-9 m-auto'>
          <div className='ribbon ribbon-top bg-yellow'>
            <IconStar />
          </div>
          <div className='row p-3 justify-content-center'>
            {isArray(data?.videos) ? (
              data?.videos?.map((video, index) => (
                <div className='col-md-6 mb-3 text-center' key={index}>
                  {video && (
                    <iframe
                      className='mb-3'
                      width='100%'
                      height='240'
                      src={video}
                      title='YouTube video player'
                      frameBorder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen
                    ></iframe>
                  )}
                  <button
                    className='btn btn-red'
                    onClick={() => {
                      setVideoUrl(video);
                      setShowModalConfirm(true);
                    }}
                  >
                    Delete
                  </button>
                </div>
              ))
            ) : (
              <div className='col-md-12 text-error text-center'>
                You have not add any videos
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
