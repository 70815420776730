import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAdmin } from '../../utils/function/logoutAdmin';

export default function Navbar() {
  const dispatch = useDispatch();
  const { username } = useSelector((state) => state.auth);

  return (
    <header className='navbar navbar-expand-md navbar-light d-print-none'>
      <div className='container-xl'>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbar-menu'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <h1 className='navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3'>
          {''}
        </h1>
        <div className='navbar-nav flex-row order-md-last'>
          <div className='nav-item dropdown'>
            <Link
              to='#'
              className='nav-link d-flex lh-1 text-reset p-0'
              data-bs-toggle='dropdown'
              aria-label='Open user menu'
            >
              <span
                className='avatar avatar-sm'
                style={{ backgroundImage: 'url(../assets/images/avatar.jpg)' }}
              ></span>
              <div className='d-none d-xl-block ps-2'>
                <div className='text-capitalize'>{username}</div>
              </div>
            </Link>
            <div className='dropdown-menu dropdown-menu-end dropdown-menu-arrow'>
              <Link to='/profile' className='dropdown-item'>
                Profile &amp; account
              </Link>
              <span
                onClick={() => logoutAdmin(dispatch)}
                style={{ cursor: 'pointer' }}
                className='dropdown-item'
              >
                Logout
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
