import { toast } from 'react-toastify';
import ApiConfig from '../../api/ApiConfig';
import { ERROR, REMOVE_COOKIE } from '../../redux/types';

// logout user
export const logoutAdmin = async (dispatch) => {
  ApiConfig.post('auth/logout', '')
    .then((res) => {
      if (res.status === 200) {
        // Successfully logged out
        dispatch({ type: REMOVE_COOKIE });
        toast.success('Successfully logged out');
      }
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        // status failed userUnauthorized token
        dispatch({ type: REMOVE_COOKIE });
      }
      if (err?.response?.status === 422) {
        Object.entries(err?.response?.data?.msg).forEach(([key, value]) => {
          dispatch({
            type: ERROR,
            payload: value[0],
          });
        });
      }
    });
};
