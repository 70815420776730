import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PublicRoute from './auth/PublicRoute';
import SuperAndAdminRoute from './auth/SuperAndAdminRoute';
import SuperRoute from './auth/SuperRoute';
import AboutUs from './pages/about/About';
import EditAboutUs from './pages/about/Edit';
import Admins from './pages/admin/Admins';
import ChangePassword from './pages/admin/ChangePassword';
import EditAdmin from './pages/admin/Edit';
import NewAdmin from './pages/admin/New';
import Login from './pages/auth/login/Login';
import Blogs from './pages/blog/Blogs';
import DetailsBlog from './pages/blog/Details';
import EditBlog from './pages/blog/Edit';
import NewBlog from './pages/blog/New';
import BooksFiles from './pages/book-file/BooksFiles';
import DetailsBookFile from './pages/book-file/Details';
import EditBookFile from './pages/book-file/Edit';
import NewBookFile from './pages/book-file/New';
import Categories from './pages/category/Categories';
import Courses from './pages/category/courses/Courses';
import DetailsCourse from './pages/category/courses/Details';
import EditCourses from './pages/category/courses/Edit';
import NewCourses from './pages/category/courses/New';
import EditCategory from './pages/category/Edit';
import NewCategory from './pages/category/New';
import DetailsVirtual from './pages/category/Virtual/Details';
import EditVirtual from './pages/category/Virtual/Edit';
import NewVirtual from './pages/category/Virtual/New';
import ConfirmationMessages from './pages/confirmation-message/ConfirmationMessages';
import EditConfirmationMessages from './pages/confirmation-message/Edit';
import Contacts from './pages/contact/Contacts';
import DetailsContact from './pages/contact/Details';
import DataCenter from './pages/data-center/DataCenter';
import EditDataCenter from './pages/data-center/Edit';
import EditFooter from './pages/footer/Edit';
import Footer from './pages/footer/Footer';
import EditHero from './pages/hero/Edit';
import Hero from './pages/hero/Hero';
import DetailsMessageReservation from './pages/message-reservation/Details';
import EditMessageReservations from './pages/message-reservation/Edit';
import MessageReservations from './pages/message-reservation/MessageReservations';
import NewPartners from './pages/partner/New';
import Partners from './pages/partner/partners';
import DetailsPrinciple from './pages/principle/Details';
import EditPrinciple from './pages/principle/Edit';
import NewPrinciples from './pages/principle/New';
import Principles from './pages/principle/Principles';
import Profile from './pages/profile/Profile';
import NewSlides from './pages/slides/New';
import Slides from './pages/slides/Slides';
import NewVideos from './pages/video/New';
import Videos from './pages/video/Videos';

function App() {
  const { isLogin, role } = useSelector((state) => state.auth);
  return (
    <div className='page'>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              <PublicRoute isLogin={isLogin}>
                <Login path='/' />
              </PublicRoute>
            }
          />
          {/* route is role super */}
          <Route element={<SuperRoute isLogin={isLogin} role={role} />}>
            <Route path='/admins' element={<Admins />} />
            <Route path='/admins/new' element={<NewAdmin />} />
            <Route path='/admins/:id/edit' element={<EditAdmin />} />
            <Route path='/admins/:id/password' element={<ChangePassword />} />
          </Route>
          {/* route is role admin or super */}
          <Route element={<SuperAndAdminRoute isLogin={isLogin} />}>
            <Route path='/profile' element={<Profile />} />
            <Route path='/hero' element={<Hero />} />
            <Route path='/hero/edit' element={<EditHero />} />
            <Route path='/data-center' element={<DataCenter />} />
            <Route path='/data-center/edit' element={<EditDataCenter />} />
            <Route path='/slides' element={<Slides />} />
            <Route path='/slides/new' element={<NewSlides />} />
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/about-us/edit' element={<EditAboutUs />} />
            <Route path='/footer' element={<Footer />} />
            <Route path='/footer/edit' element={<EditFooter />} />
            <Route path='/partners' element={<Partners />} />
            <Route path='/partners/new' element={<NewPartners />} />
            <Route path='/videos' element={<Videos />} />
            <Route path='/videos/new' element={<NewVideos />} />
            <Route path='/contacts' element={<Contacts />} />
            <Route path='/contacts/:id' element={<DetailsContact />} />
            <Route path='/message-reservations' element={<MessageReservations />} />
            <Route path='/message-reservations/:id/details' element={<DetailsMessageReservation />} />
            <Route path='/message-reservations/:id/edit' element={<EditMessageReservations />} />
            <Route path='/reservation-confirmation-messages' element={<ConfirmationMessages />} />
            <Route path='/reservation-confirmation-messages/edit' element={<EditConfirmationMessages />} />
            <Route path='/principles' element={<Principles />} />
            <Route path='/principles/new' element={<NewPrinciples />} />
            <Route path='/principles/:id/edit' element={<EditPrinciple />} />
            <Route
              path='/principles/:id/details'
              element={<DetailsPrinciple />}
            />
            <Route path='/blogs' element={<Blogs />} />
            <Route path='/blogs/new' element={<NewBlog />} />
            <Route path='/blogs/:id/edit' element={<EditBlog />} />
            <Route path='/blogs/:id/details' element={<DetailsBlog />} />
            <Route path='/books-files' element={<BooksFiles />} />
            <Route path='/books-files/new' element={<NewBookFile />} />
            <Route path='/books-files/:id/edit' element={<EditBookFile />} />
            <Route path='/books-files/:id/details' element={<DetailsBookFile />} />
            <Route path='/categories' element={<Categories />} />
            <Route path='/categories/new' element={<NewCategory />} />
            <Route path='/categories/:categoryId/edit' element={<EditCategory />} />
            <Route path='/categories/:categoryId/courses' element={<Courses />} />
            <Route path='/categories/:categoryId/courses/new' element={<NewCourses />} />
            <Route path='/categories/:categoryId/courses/:courseId/edit' element={<EditCourses />} />
            <Route path='/categories/:categoryId/courses/:courseId/details' element={<DetailsCourse />} />
            <Route path='/categories/:categoryId/virtual/new' element={<NewVirtual />} />
            <Route path='/categories/:categoryId/virtual/:courseId/edit' element={<EditVirtual />} />
            <Route path='/categories/:categoryId/virtual/:courseId/details' element={<DetailsVirtual />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
