import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import { FaSearch } from 'react-icons/fa';

export default function GlobalFilter({ filter, setFilter }) {
  const [value, setValue] = useState(filter);
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 100);
  return (
    <>
      <div className='row mb-3'>
        <div className='col-md-5 align-self-center'>
          <span className='text-muted h4'>Enter search value:</span>{' '}
        </div>
        <div className='col-md-7 search-global'>
          <input
            className='form-control rounded-pill'
            type='text'
            inputMode='search'
            placeholder='Search....'
            value={value || ''}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
          <FaSearch color='#8080802b' />
        </div>
      </div>
    </>
  );
}
