import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { IconPlus } from '@tabler/icons';

export default function Breadcrumb({
  url,
  title,
  textActive,
  btnText,
  data,
  items = [],
}) {
  const navigate = useNavigate();
  return (
    <div className='page-header mb-5'>
      <div className='row align-items-center mw-100'>
        <div className='col'>
          <div className='mb-1'>
            <ol
              className='breadcrumb breadcrumb-arrows'
              aria-label='breadcrumbs'
            >
              <li className='breadcrumb-item'>
                <Link to='/'>Home</Link>
              </li>
              {items?.map((item) => (
                <li key={item.title} className='breadcrumb-item'>
                  <Link
                    className='text-capitalize'
                    to={`/${item.title.toLowerCase()}`}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
              <li className='breadcrumb-item active' aria-current='page'>
                {textActive}
              </li>
            </ol>
          </div>
          <h2 className='page-title'>
            <span className='text-truncate'>{title}</span>
          </h2>
        </div>
        {btnText && (
          <div className='col-auto'>
            <div className='btn-list'>
              <button className='btn btn-primary' onClick={() => navigate(url,{state:data})}>
                <IconPlus /> {btnText}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
