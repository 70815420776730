import {
  DELETE_DATA,
  ERROR,
  LOAD_DATA_SUCCESS,
  PRE_REQUEST,
  SEND_DATA,
  UPDATE_DATA,
} from '../types';

const initState = {
  data: [],
  loading: false,
  error: '',
};
const ApiReducer = (state = initState, action) => {
  switch (action.type) {
    case PRE_REQUEST:
      return {
        ...state,
        data: [],
        loading: true,
        error: '',
      };
    case LOAD_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: '',
      };
    case SEND_DATA:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case UPDATE_DATA:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case DELETE_DATA:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default ApiReducer;
