import cookie from 'react-cookies';
import { LOIN_REQUEST_SUCCESS, REMOVE_COOKIE } from '../types';
const auth = cookie.load('auth');

const initState = {
  isLogin: auth ? auth.isLogin : false,
  accessToken: auth ? auth.accessToken : '',
  refreshToken: auth ? auth.refreshToken : '',
  role: auth ? auth.role : '',
  email: auth ? auth.email : '',
  username: auth ? auth.username : '',
};
const AuthReducer = (state = initState, action) => {
  switch (action.type) {
    case LOIN_REQUEST_SUCCESS: {
      const expires = new Date();
      expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
      cookie.save(
        'auth',
        JSON.stringify({
          isLogin: true,
          accessToken: action.payload.accessToken,
          refreshToken: action.payload.refreshToken,
          role: action.payload.role,
          email: action.payload.email,
          username: action.payload.username,
        })
      );
      const { isLogin, accessToken, refreshToken, role, email, username } =
        cookie.load('auth');
      return {
        ...state,
        isLogin,
        accessToken,
        refreshToken,
        role,
        email,
        username,
      };
    }
    case REMOVE_COOKIE:
      cookie.remove('auth', { path: '/' });
      return {
        ...state,
        isLogin: false,
        accessToken: '',
        refreshToken: '',
        role: '',
        email: '',
        username: '',
      };
    default:
      return state;
  }
};
export default AuthReducer;
