import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FaLocationArrow } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { editData } from '../../redux/actions/api/EditData';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loading from '../../components/loading/Loading';

export default function EditConfirmationMessages() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.auth);
  const { data, loading, error } = useSelector((state) => state.api);
  // ----------------------------------------------------------------------------------->
  useEffect(() => {
    error && toast.error(error);
  }, [dispatch, error, loading]);
  // ----------------------------------------------------------------------------------->
  const initialValues = {
    // initial Values input felids
    messages: {
      ongoing: data?.reservation_confirmation_messages?.ongoing,
      upcoming: data?.reservation_confirmation_messages?.upcoming,
      virtual: data?.reservation_confirmation_messages?.virtual,
    },
  };
  // ----------------------------------------------------------------------------------->
  const validationSchema = Yup.object({
    // validation and check input is not empty
    messages: Yup.object().shape({
      ongoing: Yup.string()
        .required(`message ongoing required`)
        .min(3, `The message ongoing you should not be less than 3 characters`)
        .max(1000, `The message ongoing you must not than 1000 characters`),
      upcoming: Yup.string()
        .required(`message upcoming required`)
        .min(3, `The message upcoming you should not be less than 3 characters`)
        .max(1000, `The message upcoming you must not than 1000 characters`),
      virtual: Yup.string()
        .required(`message virtual required`)
        .min(3, `The message virtual you should not be less than 3 characters`)
        .max(1000, `The message virtual you must not than 1000 characters`),
    }),
  });
  // ----------------------------------------------------------------------------------->
  const onSubmit = (values) => {
    // handel to modify messages
    var data = new FormData();
    data.append(
      'reservation_confirmation_messages[ongoing]',
      values?.messages?.ongoing
    );
    data.append(
      'reservation_confirmation_messages[upcoming]',
      values?.messages?.upcoming
    );
    data.append(
      'reservation_confirmation_messages[virtual]',
      values?.messages?.virtual
    );
    data.append('_method', 'PUT');
    dispatch(
      editData(
        'reservation-confirmation-messages',
        accessToken,
        data,
        navigate,
        '/reservation-confirmation-messages',
        true
      )
    );
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  return (
    <>
      <Loading isLoading={loading} />
      <Breadcrumb
        title='Modify reservation confirmation message'
        textActive='Details'
        items={[{ title: 'ReservationConfirmationMessages' }]}
      />
      <div className='card rounded-1 mb-4 p-3'>
        <form onSubmit={formik.handleSubmit}>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='ongoing' className='text-muted'>
                  Enter your ongoing <span className='required'>*</span>
                </label>
                <textarea
                  className='form-control form-control-user'
                  name='messages.ongoing'
                  {...formik.getFieldProps('messages.ongoing')}
                  id='ongoing'
                  cols='30'
                  inputMode='text'
                  rows='5'
                ></textarea>
                {formik.touched.messages?.ongoing &&
                formik.errors.messages?.ongoing ? (
                  <div className='text-error'>
                    {formik.errors.messages?.ongoing}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='upcoming' className='text-muted'>
                  Enter your upcoming <span className='required'>*</span>
                </label>
                <textarea
                  className='form-control form-control-user'
                  name='messages.upcoming'
                  id='upcoming'
                  inputMode='text'
                  {...formik.getFieldProps('messages.upcoming')}
                  cols='30'
                  rows='5'
                ></textarea>
                {formik.touched.messages?.upcoming &&
                formik.errors.messages?.upcoming ? (
                  <div className='text-error'>
                    {formik.errors.messages?.upcoming}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='virtual' className='text-muted'>
                  Enter your virtual <span className='required'>*</span>
                </label>
                <textarea
                  className='form-control form-control-user'
                  name='messages.virtual'
                  id='virtual'
                  inputMode='text'
                  {...formik.getFieldProps('messages.virtual')}
                  cols='30'
                  rows='5'
                ></textarea>
                {formik.touched.messages?.virtual &&
                formik.errors.messages?.virtual ? (
                  <div className='text-error'>
                    {formik.errors.messages?.virtual}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='col-md-12 mt-3 text-center'>
              <button
                className='btn btn-primary btn-user btn-inline'
                type='submit'
              >
                <FaLocationArrow style={{ marginRight: '.5rem' }} /> Edit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
