import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router';
import IdleTimer from 'react-idle-timer';
import Navbar from '../navbar/Navbar';
import Sidebar from '../sidebar/Sidebar';
import Copyright from '../copyright/Copyright';
import { logoutAdmin } from '../../utils/function/logoutAdmin';

const Layout = () => {
  const dispatch = useDispatch();
  const idleTimerRef = useRef(null);
  const sessionTimeOutRef = useRef(null);
  // ----------------------------------------------------------------------------------->
  const onIdle = () => {
    // check is user is not active
    sessionTimeOutRef.current = setTimeout(LogOut, 5000);
  };
  // ----------------------------------------------------------------------------------->
  const LogOut = async () => {
    // logout is user not active dashboard one minute
    await logoutAdmin(dispatch);
    clearTimeout(sessionTimeOutRef.current);
  };
  return (
    <>
      <IdleTimer ref={idleTimerRef} timeout={2669 * 1000} onIdle={onIdle}>
        <Navbar />
        <Sidebar />
        <div className='page-wrapper'>
          <div className='page-body mb-5'>
            <div className='container-xl mb-5'>
              <Outlet />
            </div>
          </div>
          <Copyright />
        </div>
      </IdleTimer>
    </>
  );
};
export default Layout;
