import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FaLocationArrow } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { editData } from '../../redux/actions/api/EditData';
import { checkValidationPhone } from '../../utils/function';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loading from '../../components/loading/Loading';

export default function EditAdmin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { accessToken } = useSelector((state) => state.auth);
  const admin = useSelector((state) =>
    state.api.data.find((admin) => admin.id.toString() === id)
  );
  const { loading, error } = useSelector((state) => state.api);
  // ----------------------------------------------------------------------------------->
  useEffect(() => {
    error && toast.error(error);
  }, [dispatch, error, loading]);
  // ----------------------------------------------------------------------------------->
  const initialValues = {
    // initial Values input felids
    name: admin?.name,
    email: admin?.email,
    phone: admin?.phone,
    role: admin?.role,
  };
  // ----------------------------------------------------------------------------------->
  const validationSchema = Yup.object({
    // validation and check input is not empty
    name: Yup.string()
      .required(`name required`)
      .min(3, `The name you should not be less than 3 characters`)
      .max(30, `The name you must not than 30 characters`),
    email: Yup.string()
      .required(`email required`)
      .min(6, `The email you should not be less than 6 characters`)
      .max(40, `The email you must not than 40 characters`)
      .email('Invalid email format'),
    phone: Yup.string()
      .required(`phone required`)
      .min(10, `The phone you should not be less than 10 characters`)
      .max(12, `The phone you must not than 12 characters`),
    role: Yup.string().required(`role required`),
  });
  // ----------------------------------------------------------------------------------->
  const onSubmit = (values) => {
    // handel to modify admin
    const data = {
      name: values?.name,
      email: values?.email,
      phone: values?.phone,
      role: values?.role,
    };
    dispatch(editData(`admins/${id}`, accessToken, data, navigate, '/admins'));
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  return (
    <>
          <Loading isLoading={loading} />
      <Breadcrumb
        title='Modify admin'
        textActive='Modify'
        items={[{ title: 'Admins' }]}
      />
      <div className='card rounded-1 mb-4 p-3'>
        <form onSubmit={formik.handleSubmit}>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='name' className='text-muted'>
                  Enter your name <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='name'
                  id='name'
                  type='text'
                  inputMode='text'
                  {...formik.getFieldProps('name')}
                  placeholder='John Due'
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className='text-error'>{formik.errors.name}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='email' className='text-muted'>
                  Enter your email <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='email'
                  id='email'
                  inputMode='email'
                  type='email'
                  placeholder='example@mail.com'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className='text-error'>{formik.errors.email}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='email' className='text-muted'>
                  Enter your phone <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='phone'
                  inputMode='tel'
                  id='phone'
                  type='tel'
                  placeholder='249123456789'
                  {...formik.getFieldProps('phone')}
                  onInput={checkValidationPhone}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className='text-error'>{formik.errors.phone}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='role' className='text-muted'>
                  Select your role admin <span className='required'>*</span>
                </label>
                <select
                  name='role'
                  id='role'
                  {...formik.getFieldProps('role')}
                  className='form-control'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option disabled>Select your role admin</option>
                  <option value='super'>super</option>
                  <option value='admin'>admin</option>
                </select>
                {formik.touched.role && formik.errors.role ? (
                  <div className='text-error'>{formik.errors.role}</div>
                ) : null}{' '}
              </div>
            </div>
            <div className='col-md-12 mt-3 text-center'>
              <button
                className='btn btn-primary btn-user btn-inline'
                type='submit'
              >
                <FaLocationArrow style={{ marginRight: '.5rem' }} /> Edit Admin
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
