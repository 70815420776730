import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FaLocationArrow } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { editData } from '../../redux/actions/api/EditData';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loading from '../../components/loading/Loading';
import {
  checkIfFilesAreCorrectType,
  checkIfFilesAreTooBig,
} from '../../utils/function';

export default function EditBookFile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { accessToken } = useSelector((state) => state.auth);
  const bookFile = useSelector((state) =>
    state.api.data.find((bookFile) => bookFile.id.toString() === id)
  );
  const { loading, error } = useSelector((state) => state.api);
  // ----------------------------------------------------------------------------------->
  useEffect(() => {
    error && toast.error(error);
  }, [dispatch, error, loading]);
  // ----------------------------------------------------------------------------------->
  const initialValues = {
    // initial Values input felids
    image: '',
    file: '',
    title: {
      ar: bookFile?.title_ar,
      en: bookFile?.title_en,
    },
    description: {
      ar: bookFile?.description_ar,
      en: bookFile?.description_en,
    },
  };
  // ----------------------------------------------------------------------------------->
  const validationSchema = Yup.object({
    // validation and check input is not empty
    title: Yup.object().shape({
      ar: Yup.string()
        .required(`title ar required`)
        .min(3, `The title ar you should not be less than 3 characters`)
        .max(70, `The title ar you must not than 70 characters`),
      en: Yup.string()
        .required(`title en required`)
        .min(3, `The title en you should not be less than 3 characters`)
        .max(70, `The title en you must not than 70 characters`),
    }),
    description: Yup.object().shape({
      ar: Yup.string()
        .required(`description ar required`)
        .min(3, `The description ar you should not be less than 3 characters`)
        .max(500, `The description ar you must not than 500 characters`),
      en: Yup.string()
        .required(`description en required`)
        .min(3, `The description en you should not be less than 3 characters`)
        .max(500, `The description en you must not than 500 characters`),
    }),
    image: Yup.mixed()
      .nullable()
      .test(
        'fileSize',
        'File size too large, max file size is 2 Mb',
        (value) => !value || checkIfFilesAreTooBig(value)
      )
      .test(
        'fileFormat',
        'Uploaded file has unsupported format.',
        (value) => !value || checkIfFilesAreCorrectType(value)
      ),
  });
  // ----------------------------------------------------------------------------------->
  const onSubmit = (values) => {
    // handel to modify book file
    var data = new FormData();
    data.append('image', values?.image || []);
    data.append('file', values?.file || []);
    data.append('title_en', values?.title?.en);
    data.append('title_ar', values?.title?.ar);
    data.append('description_en', values?.description?.en);
    data.append('description_ar', values?.description?.ar);
    data.append('_method', 'PUT');
    dispatch(
      editData(
        `downloadables/${id}`,
        accessToken,
        data,
        navigate,
        '/books-files',
        true
      )
    );
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  return (
    <>
      <Loading isLoading={loading} />
      <Breadcrumb
        title='Edit a book file'
        textActive='Edit'
        items={[{ title: 'BooksFiles' }]}
      />
      <div className='card rounded-1 mb-4 p-3'>
        <form onSubmit={formik.handleSubmit}>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='title_ar' className='text-muted'>
                  Enter your title ar <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='title.ar'
                  id='title_ar'
                  inputMode='text'
                  type='text'
                  {...formik.getFieldProps('title.ar')}
                  placeholder='write title here....'
                />
                {formik.touched.title?.ar && formik.errors.title?.ar ? (
                  <div className='text-error'>{formik.errors.title?.ar}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='title_en' className='text-muted'>
                  Enter your title en <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='title.en'
                  id='title_en'
                  inputMode='text'
                  type='text'
                  placeholder='write title here....'
                  {...formik.getFieldProps('title.en')}
                />
                {formik.touched.title?.en && formik.errors.title?.en ? (
                  <div className='text-error'>{formik.errors.title?.en}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='description_ar' className='text-muted'>
                  Enter your description ar <span className='required'>*</span>
                </label>
                <textarea
                  className='form-control form-control-user'
                  name='description.ar'
                  {...formik.getFieldProps('description.ar')}
                  placeholder='write description here....'
                  id='description_ar'
                  cols='30'
                  inputMode='text'
                  rows='5'
                ></textarea>
                {formik.touched.description?.ar &&
                formik.errors.description?.ar ? (
                  <div className='text-error'>
                    {formik.errors.description?.ar}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='description_en' className='text-muted'>
                  Enter your description en <span className='required'>*</span>
                </label>
                <textarea
                  className='form-control form-control-user'
                  name='description.en'
                  inputMode='text'
                  placeholder='write description here....'
                  id='description_en'
                  {...formik.getFieldProps('description.en')}
                  cols='30'
                  rows='5'
                ></textarea>
                {formik.touched.description?.en &&
                formik.errors.description?.en ? (
                  <div className='text-error'>
                    {formik.errors.description?.en}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='image' className='text-muted'>
                  Enter your image <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='image'
                  accept='image/jpeg,image/png,image/svg'
                  id='image'
                  onChange={(e) =>
                    formik.setFieldValue('image', e.target.files[0])
                  }
                  type='file'
                />
                {formik.touched.image && formik.errors.image ? (
                  <div className='text-error'>{formik.errors.image}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='file' className='text-muted'>
                  Enter your file <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='file'
                  accept='.pdf,.doc,.ppt'
                  id='file'
                  onChange={(e) =>
                    formik.setFieldValue('file', e.target.files[0])
                  }
                  type='file'
                />
                {formik.touched.file && formik.errors.file ? (
                  <div className='text-error'>{formik.errors.file}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-12 mt-3 text-center'>
              <button
                className='btn btn-primary btn-user btn-inline'
                type='submit'
              >
                <FaLocationArrow style={{ marginRight: '.5rem' }} /> Edit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
