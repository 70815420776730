import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FaLocationArrow } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { editData } from '../../redux/actions/api/EditData';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loading from '../../components/loading/Loading';

export default function EditAboutUs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.auth);
  const { data: about, loading, error } = useSelector((state) => state.api);
  // ----------------------------------------------------------------------------------->
  useEffect(() => {
    error && toast.error(error);
  }, [dispatch, error, loading]);
  // ----------------------------------------------------------------------------------->
  const initialValues = {
    // initial Values input felids
    about: {
      ar: about?.about_us_ar,
      en: about?.about_us_en,
    },
  };
  // ----------------------------------------------------------------------------------->
  const validationSchema = Yup.object({
    // validation and check input is not empty
    about: Yup.object().shape({
      ar: Yup.string()
        .required(`about ar required`)
        .min(3, `The about ar you should not be less than 3 characters`)
        .max(1000, `The about ar you must not than 1000 characters`),
      en: Yup.string()
        .required(`about en required`)
        .min(3, `The about en you should not be less than 3 characters`)
        .max(1000, `The about en you must not than 1000 characters`),
    }),
  });
  // ----------------------------------------------------------------------------------->
  const onSubmit = (values) => {
    // handel to modify about
    var data = new FormData();
    data.append('about_us_ar', values?.about?.ar);
    data.append('about_us_en', values?.about?.en);
    data.append('_method', 'PUT');
    dispatch(
      editData('about-us', accessToken, data, navigate, '/about-us', true)
    );
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  return (
    <>
      <Loading isLoading={loading} />
      <Breadcrumb
        title='Modify about the center'
        textActive='Details'
        items={[{ title: 'About Us' }]}
      />
      <div className='card rounded-1 mb-4 p-3'>
        <form onSubmit={formik.handleSubmit}>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='about_ar' className='text-muted'>
                  Enter your about ar <span className='required'>*</span>
                </label>
                <textarea
                  className='form-control form-control-user text-end'
                  name='about.ar'
                  {...formik.getFieldProps('about.ar')}
                  placeholder='......اكتب الوصف هنا '
                  id='about_ar'
                  cols='30'
                  inputMode='text'
                  rows='5'
                ></textarea>
                {formik.touched.about?.ar && formik.errors.about?.ar ? (
                  <div className='text-error'>{formik.errors.about?.ar}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='about_en' className='text-muted'>
                  Enter your about en <span className='required'>*</span>
                </label>
                <textarea
                  className='form-control form-control-user'
                  name='about.en'
                  placeholder='write about here....'
                  id='about_en'
                  inputMode='text'
                  {...formik.getFieldProps('about.en')}
                  cols='30'
                  rows='5'
                ></textarea>
                {formik.touched.about?.en && formik.errors.about?.en ? (
                  <div className='text-error'>{formik.errors.about?.en}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-12 mt-3 text-center'>
              <button
                className='btn btn-primary btn-user btn-inline'
                type='submit'
              >
                <FaLocationArrow style={{ marginRight: '.5rem' }} /> Edit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
