import { useLocation } from 'react-router';
import moment from 'moment';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';

export default function DetailsPrinciple() {
  const location = useLocation();
  const {
    id,
    image,
    title_ar,
    title_en,
    description_ar,
    description_en,
    created_at,
    updated_at,
  } = location?.state;
  return (
    <>
      <Breadcrumb
        title='Details a principle'
        textActive='Details'
        items={[{ title: 'Principles' }]}
      />
      {Object.values(location?.state).length > 0 ? (
        <div className='container-fluid'>
          <div className='row col-11 col-md-8 m-auto details-info'>
            <div className='col-12 text-center'>
              <img
                className='rounded-circle rounded mb-3 m-1'
                width='100'
                height='100'
                src={image}
                alt={title_en}
              />
            </div>
            <div className='col-md-12'>
              <div className='row border-bottom border-end border-start'>
                <div className='col-md-4'>
                  <h3 className='title'>id :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>#{id}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-6 pt-4 border-bottom border-end border-start'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>title ar :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>{title_ar}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-6 pt-4 border-bottom border-end border-start border-end-mb-0'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>title en :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>{title_en}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-12 pt-4 border-bottom border-end border-start'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>description ar :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title text-end'>{description_ar}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-12 pt-4 border-bottom border-end border-start'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>description en :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>{description_en}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-6 pt-4 border-bottom border-end border-start'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>created at :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>
                    {moment(created_at).format('LLL')}{' '}
                  </h4>
                </div>
              </div>
            </div>
            <div className='col-md-6 pt-4 border-bottom border-end border-start border-end-mb-0'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>updated at :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>
                    {moment(updated_at).format('LLL')}{' '}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='text-error text-center'>
          You have not add any data equals this id principles
        </div>
      )}
    </>
  );
}
