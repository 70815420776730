import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  IconBrandTabler,
  IconWind,
  IconBoxModel,
  IconUsers,
  IconLayoutBottombar,
  IconBook,
  IconBuildingSkyscraper,
  IconDrone,
  IconInfoCircle,
  IconAtom2,
  IconAssembly,
  IconArticle,
  IconFileDatabase,
  IconBrandYoutube,
  IconMessage,
  IconMessageCircle,
} from '@tabler/icons';

export default function Sidebar() {
  const { role } = useSelector((state) => state.auth);

  return (
    <aside className='navbar navbar-vertical navbar-expand-lg navbar-transparent bg-white'>
      <div className='container-fluid'>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbar-menu'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <h1 className='navbar-brand navbar-brand-autodark'>
          <Link to='/'>
            <IconBrandTabler
              style={{ height: '3rem', width: '3rem' }}
              color='green'
            />
          </Link>
        </h1>
        <div className='collapse navbar-collapse' id='navbar-menu'>
          <ul className='navbar-nav pt-lg-3'>
            <li className='nav-item'>
              <Link className='nav-link' to='/hero'>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconAtom2 color='#4263eb' />
                </span>
                <span className='nav-link-title'>Hero</span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/slides'>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconAssembly color='#4263eb' />
                </span>
                <span className='nav-link-title'>Slides</span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/about-us'>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconInfoCircle color='#4263eb' />
                </span>
                <span className='nav-link-title'>About Center</span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/principles'>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconWind color='#4263eb' />
                </span>
                <span className='nav-link-title'>Principles</span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/blogs'>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconArticle color='#4263eb' />
                </span>
                <span className='nav-link-title'>Blogs</span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/data-center'>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconFileDatabase color='#4263eb' />
                </span>
                <span className='nav-link-title'>Data Center</span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/videos'>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconBrandYoutube color='#4263eb' />
                </span>
                <span className='nav-link-title'>Videos</span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/categories '>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconBoxModel color='#4263eb' />
                </span>
                <span className='nav-link-title'>Categories </span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/books-files '>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconBook color='#4263eb' />
                </span>
                <span className='nav-link-title'>Books Files </span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/partners'>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconBuildingSkyscraper color='#4263eb' />
                </span>
                <span className='nav-link-title'>Partners</span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/message-reservations'>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconMessageCircle color='#4263eb' />
                </span>
                <span className='nav-link-title'>Message Reservations</span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className='nav-link'
                to='/reservation-confirmation-messages'
              >
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconMessage color='#4263eb' />
                </span>
                <span className='nav-link-title'>Confirmation Messages</span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/contacts'>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconDrone color='#4263eb' />
                </span>
                <span className='nav-link-title'>Contact Us</span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/footer'>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconLayoutBottombar color='#4263eb' />
                </span>
                <span className='nav-link-title'>Footer</span>
              </Link>
            </li>
            {role === 'super' && (
              <li className='nav-item'>
                <Link className='nav-link' to='/admins'>
                  <span className='nav-link-icon d-md-none d-lg-inline-block'>
                    <IconUsers color='#4263eb' />
                  </span>
                  <span className='nav-link-title'>Admins</span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </aside>
  );
}
