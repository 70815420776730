import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FaLocationArrow } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { sendData } from '../../redux/actions/api/SendData';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loading from '../../components/loading/Loading';

export default function NewCategory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.api);
  // ----------------------------------------------------------------------------------->
  useEffect(() => {
    error && toast.error(error);
  }, [dispatch, error, loading]);
  // ----------------------------------------------------------------------------------->
  const initialValues = {
    // initial Values input felids
    title: {
      ar: '',
      en: '',
    },
  };
  // ----------------------------------------------------------------------------------->
  const validationSchema = Yup.object({
    // validation and check input is not empty
    title: Yup.object().shape({
      ar: Yup.string()
        .required(`title ar required`)
        .min(3, `The title ar you should not be less than 3 characters`)
        .max(70, `The title ar you must not than 70 characters`),
      en: Yup.string()
        .required(`title en required`)
        .min(3, `The title en you should not be less than 3 characters`)
        .max(70, `The title en you must not than 70 characters`),
    }),
  });
  // ----------------------------------------------------------------------------------->
  const onSubmit = (values) => {
    // handel to add new product
    var data = new FormData();
    data.append('title_en', values?.title?.en);
    data.append('title_ar', values?.title?.ar);
    dispatch(
      sendData(`course-categories`, accessToken, data, navigate, '/categories')
    );
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  return (
    <>
      <Loading isLoading={loading} />
      <Breadcrumb
        title='Add a new category'
        textActive='New'
        items={[{ title: 'Categories' }]}
      />
      <div className='card rounded-1 mb-4 p-3'>
        <form onSubmit={formik.handleSubmit}>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='title_ar' className='text-muted'>
                  Enter your title ar <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='title.ar'
                  id='title_ar'
                  type='text'
                  inputMode='text'
                  {...formik.getFieldProps('title.ar')}
                  placeholder='write title here....'
                />
                {formik.touched.title?.ar && formik.errors.title?.ar ? (
                  <div className='text-error'>{formik.errors.title?.ar}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='title_en' className='text-muted'>
                  Enter your title en <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='title.en'
                  id='title_en'
                  type='text'
                  inputMode='text'
                  placeholder='write title here....'
                  {...formik.getFieldProps('title.en')}
                />
                {formik.touched.title?.en && formik.errors.title?.en ? (
                  <div className='text-error'>{formik.errors.title?.en}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-12 mt-3 text-center'>
              <button
                className='btn btn-primary btn-user btn-inline'
                type='submit'
              >
                <FaLocationArrow style={{ marginRight: '.5rem' }} /> New
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
