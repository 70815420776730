import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllData } from '../../redux/actions/api/GetAllData';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loading from '../../components/loading/Loading';
import '../../styles/profile.css';

export default function Profile() {
  const dispatch = useDispatch();
  const { accessToken, role } = useSelector((state) => state?.auth);
  const { data: info, loading, error } = useSelector((state) => state?.api);
  // ----------------------------------------------------------------------------------->
  useEffect(() => {
    dispatch(getAllData('auth/profile', accessToken));
  }, [accessToken, dispatch]);
  return (
    <>
      <Breadcrumb title='Profile user info' textActive='Profile' />
      {loading ? (
        <Loading isLoading={loading} />
      ) : error ? (
        error
      ) : (
        <div className='user-profile mt-5 mb-5'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-7 col-11 m-auto'>
                <div className='user-info'>
                  <form>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='image text-center mb-5 mt-2'>
                          <img
                            className='rounded-circle'
                            src='../assets/images/avatar.jpg'
                            id='user_photo'
                            alt='imageUserProfile'
                          />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label className='text-muted'>
                            Name <span className='required'>*</span>
                          </label>
                          <input
                            className='form-control'
                            type='text'
                            id='name'
                            name='name'
                            inputMode='text'
                            maxLength='20'
                            readOnly
                            value={info?.name}
                          />
                        </div>
                      </div>
                      <div className='col-md-6 '>
                        <div className='form-group'>
                          <label className='text-muted'>
                            Email <span className='required'>*</span>
                          </label>
                          <input
                            className='form-control'
                            type='email'
                            name='email'
                            inputMode='email'
                            value={info?.email}
                            readOnly
                            placeholder='example@gmail.com'
                          />
                        </div>
                      </div>
                      <div className='col-md-6 '>
                        <div className='form-group'>
                          <label className='text-muted'>
                            Admin role <span className='required'>*</span>
                          </label>
                          <input
                            className='form-control'
                            type='text'
                            name='role'
                            id='role'
                            inputMode='text'
                            readOnly
                            value={role}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
