import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FaLocationArrow } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { checkValidationPhone } from '../../../utils/function';
import { sendData } from '../../../redux/actions/api/SendData';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import Loading from '../../../components/loading/Loading';

export default function NewVirtual() {
  const { categoryId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.api);
  // ----------------------------------------------------------------------------------->
  useEffect(() => {
    error && toast.error(error);
  }, [dispatch, error, loading]);
  // ----------------------------------------------------------------------------------->
  const initialValues = {
    // initial Values input felids
    video: '',
    intro: '',
    title: {
      ar: '',
      en: '',
    },
    description: {
      ar: '',
      en: '',
    },
    hours: '',
    videoContent: '',
    fees: '',
  };
  // ----------------------------------------------------------------------------------->
  const validationSchema = Yup.object({
    // validation and check input is not empty
    title: Yup.object().shape({
      ar: Yup.string()
        .required(`title ar required`)
        .min(3, `The title ar you should not be less than 3 characters`)
        .max(70, `The title ar you must not than 70 characters`),
      en: Yup.string()
        .required(`title en required`)
        .min(3, `The title en you should not be less than 3 characters`)
        .max(70, `The title en you must not than 70 characters`),
    }),
    description: Yup.object().shape({
      ar: Yup.string()
        .required(`description ar required`)
        .min(3, `The description ar you should not be less than 3 characters`)
        .max(500, `The description ar you must not than 500 characters`),
      en: Yup.string()
        .required(`description en required`)
        .min(3, `The description en you should not be less than 3 characters`)
        .max(500, `The description en you must not than 500 characters`),
    }),
    video: Yup.mixed().required(`video url required`),
    intro: Yup.mixed().required(`intro url required`),
    hours: Yup.string().required(`hours required`),
    fees: Yup.string().required(`fees required`),
    videoContent: Yup.string().required(`video content required`),
  });
  // ----------------------------------------------------------------------------------->
  const onSubmit = (values) => {
    // handel to add new courses
    var data = new FormData();
    data.append('course_category_id', categoryId);
    data.append('video', values?.video);
    data.append('intro', values?.intro);
    data.append('title_en', values?.title?.en);
    data.append('title_ar', values?.title?.ar);
    data.append('hours', values?.hours);
    data.append('fees', values?.fees);
    data.append('video_content', values?.videoContent);
    data.append('description_en', values?.description?.en);
    data.append('description_ar', values?.description?.ar);
    dispatch(
      sendData(`virtual-courses`, accessToken, data, navigate, `/categories`)
    );
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  return (
    <>
      <Loading isLoading={loading} />

      <Breadcrumb
        title='Add a new virtual course'
        textActive='New'
        items={[{ title: 'Categories' }, { title: 'Virtual' }]}
      />
      <div className='card rounded-1 mb-4 p-3'>
        <form onSubmit={formik.handleSubmit}>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='title_ar' className='text-muted'>
                  Enter your title ar <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='title.ar'
                  id='title_ar'
                  type='text'
                  inputMode='text'
                  {...formik.getFieldProps('title.ar')}
                  placeholder='write title here....'
                />
                {formik.touched.title?.ar && formik.errors.title?.ar ? (
                  <div className='text-error'>{formik.errors.title?.ar}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='title_en' className='text-muted'>
                  Enter your title en <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='title.en'
                  id='title_en'
                  type='text'
                  inputMode='text'
                  placeholder='write title here....'
                  {...formik.getFieldProps('title.en')}
                />
                {formik.touched.title?.en && formik.errors.title?.en ? (
                  <div className='text-error'>{formik.errors.title?.en}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='hours' className='text-muted'>
                  Enter your hours <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='hours'
                  id='hours'
                  type='text'
                  {...formik.getFieldProps('hours')}
                />
                {formik.touched.hours && formik.errors.hours ? (
                  <div className='text-error'>{formik.errors.hours}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='fees' className='text-muted'>
                  Enter your fees <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='fees'
                  id='fees'
                  onInput={(e) => checkValidationPhone(e.target.value)}
                  type='number'
                  {...formik.getFieldProps('fees')}
                />
                {formik.touched.fees && formik.errors.fees ? (
                  <div className='text-error'>{formik.errors.fees}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='fees' className='text-muted'>
                  Enter your video url <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='video'
                  id='video'
                  type='url'
                  {...formik.getFieldProps('video')}
                />
                {formik.touched.video && formik.errors.video ? (
                  <div className='text-error'>{formik.errors.video}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='intro' className='text-muted'>
                  Enter your intro video url <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='intro'
                  id='intro'
                  type='url'
                  {...formik.getFieldProps('intro')}
                />
                {formik.touched.intro && formik.errors.intro ? (
                  <div className='text-error'>{formik.errors.intro}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='description_ar' className='text-muted'>
                  Enter your description ar <span className='required'>*</span>
                </label>
                <textarea
                  className='form-control form-control-user'
                  name='description.ar'
                  inputMode='text'
                  {...formik.getFieldProps('description.ar')}
                  placeholder='write description here....'
                  id='description_ar'
                  cols='30'
                  rows='5'
                ></textarea>
                {formik.touched.description?.ar &&
                formik.errors.description?.ar ? (
                  <div className='text-error'>
                    {formik.errors.description?.ar}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='description_en' className='text-muted'>
                  Enter your description en <span className='required'>*</span>
                </label>
                <textarea
                  className='form-control form-control-user'
                  name='description.en'
                  inputMode='text'
                  placeholder='write description here....'
                  id='description_en'
                  {...formik.getFieldProps('description.en')}
                  cols='30'
                  rows='5'
                ></textarea>
                {formik.touched.description?.en &&
                formik.errors.description?.en ? (
                  <div className='text-error'>
                    {formik.errors.description?.en}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='video_content' className='text-muted'>
                  Enter your video content<span className='required'>*</span>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    formik.setFieldValue('videoContent', data);
                  }}
                />
                {formik.touched.videoContent && formik.errors.videoContent ? (
                  <div className='text-error'>{formik.errors.videoContent}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-12 mt-3 text-center'>
              <button
                className='btn btn-primary btn-user btn-inline'
                type='submit'
              >
                <FaLocationArrow style={{ marginRight: '.5rem' }} /> New
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
