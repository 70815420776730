import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FaLocationArrow } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { editData } from '../../redux/actions/api/EditData';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loading from '../../components/loading/Loading';
import {
  checkIfFilesAreCorrectType,
  checkIfFilesAreTooBig,
} from '../../utils/function';

export default function EditDataCenter() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.auth);
  const {
    data: dataCenter,
    loading,
    error,
  } = useSelector((state) => state.api);
  // ----------------------------------------------------------------------------------->
  useEffect(() => {
    error && toast.error(error);
  }, [dispatch, error, loading]);
  // ----------------------------------------------------------------------------------->
  const initialValues = {
    // initial Values input felids
    dataCenter: {
      ar: dataCenter?.data_center_description_ar,
      en: dataCenter?.data_center_description_en,
    },
    image: '',
  };
  // ----------------------------------------------------------------------------------->
  const validationSchema = Yup.object({
    // validation and check input is not empty
    dataCenter: Yup.object().shape({
      ar: Yup.string()
        .required(`Data Center ar required`)
        .min(3, `The Data Center ar you should not be less than 3 characters`)
        .max(1000, `The Data Center ar you must not than 1000 characters`),
      en: Yup.string()
        .required(`Data Center en required`)
        .min(3, `The Data Center en you should not be less than 3 characters`)
        .max(1000, `The Data Center en you must not than 1000 characters`),
    }),
    image: Yup.mixed()
      .required(`image required`)
      .test(
        'fileSize',
        'File size too large, max file size is 2 Mb',
        (value) => !value || checkIfFilesAreTooBig(value)
      )
      .test(
        'fileFormat',
        'Uploaded file has unsupported format.',
        (value) => !value || checkIfFilesAreCorrectType(value)
      ),
  });
  // ----------------------------------------------------------------------------------->
  const onSubmit = (values) => {
    // handel to modify data Center
    var data = new FormData();
    data.append('data_center_image', values?.image || []);
    data.append('data_center_description_ar', values?.dataCenter?.ar);
    data.append('data_center_description_en', values?.dataCenter?.en);
    data.append('_method', 'PUT');
    dispatch(
      editData('data-center', accessToken, data, navigate, '/data-center', true)
    );
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  return (
    <>
      <Loading isLoading={loading} />
      <Breadcrumb
        title='Modify Data Center'
        textActive='Details'
        items={[{ title: 'Data Center' }]}
      />
      <div className='card rounded-1 mb-4 p-3'>
        <form onSubmit={formik.handleSubmit}>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='dataCenter_ar' className='text-muted'>
                  Enter your data center ar <span className='required'>*</span>
                </label>
                <textarea
                  className='form-control form-control-user text-end'
                  name='dataCenter.ar'
                  {...formik.getFieldProps('dataCenter.ar')}
                  id='dataCenter_ar'
                  cols='30'
                  inputMode='text'
                  rows='5'
                ></textarea>
                {formik.touched.dataCenter?.ar &&
                formik.errors.dataCenter?.ar ? (
                  <div className='text-error'>
                    {formik.errors.dataCenter?.ar}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='dataCenter_en' className='text-muted'>
                  Enter your data center en <span className='required'>*</span>
                </label>
                <textarea
                  className='form-control form-control-user'
                  name='dataCenter.en'
                  id='dataCenter_en'
                  inputMode='text'
                  {...formik.getFieldProps('dataCenter.en')}
                  cols='30'
                  rows='5'
                ></textarea>
                {formik.touched.dataCenter?.en &&
                formik.errors.dataCenter?.en ? (
                  <div className='text-error'>
                    {formik.errors.dataCenter?.en}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='image' className='text-muted'>
                  Enter your image <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='image'
                  accept='image/jpeg,image/png,image/svg'
                  id='image'
                  onChange={(e) =>
                    formik.setFieldValue('image', e.target.files[0])
                  }
                  type='file'
                />
                {formik.touched.image && formik.errors.image ? (
                  <div className='text-error'>{formik.errors.image}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-12 mt-3 text-center'>
              <button
                className='btn btn-primary btn-user btn-inline'
                type='submit'
              >
                <FaLocationArrow style={{ marginRight: '.5rem' }} /> Edit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
