import React from 'react';

export default function Copyright() {
  return (
    <footer className='footer border-0 d-print-none mt-5 fixed-bottom'>
      <div className='container-xl'>
        <div className='row text-center align-items-center justify-content-center'>
          <div className='col-12 col-lg-auto mt-3 mt-lg-0'>
            <ul className='list-inline list-inline-dots mb-0'>
              <li className='list-inline-item'>
                Copyright © {new Date().getFullYear()}{' '}
                <a
                  href='https://ease-group.com'
                  target='_blank'
                  translate='no'
                  className='link-secondary'
                  rel='noreferrer'
                >
                  EaseGroup
                </a>
                . All rights reserved.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
