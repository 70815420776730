import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage, FieldArray } from 'formik';
import { MdAdd, MdRemove } from 'react-icons/md';
import { FaLocationArrow } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { sendData } from '../../redux/actions/api/SendData';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loading from '../../components/loading/Loading';
import TextErrorMessage from '../../components/message/TextErrorMessage';

export default function NewVideos() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state?.auth);
  const { loading, error } = useSelector((state) => state?.api);
  // ----------------------------------------------------------------------------------->
  useEffect(() => {
    error && toast.error(error);
  }, [dispatch, error, loading]);
  // ----------------------------------------------------------------------------------->
  const initialValues = {
    // initial Values input felids
    videos: [{ video: '' }],
  };
  // ----------------------------------------------------------------------------------->
  const validationSchema = Yup.object({
    // validation and check input is not empty
    videos: Yup.array().of(
      Yup.object().shape({
        video: Yup.mixed().required(`video is required`),
      })
    ),
  });
  // ----------------------------------------------------------------------------------->
  const onSubmit = (values) => {
    // handel to modify videos
    var data = new FormData();
    for (let i = 0; i < values?.videos.length; i++) {
      data.append(`videos[${i.toString()}]`, values?.videos[i].video || []);
    }
    dispatch(sendData(`videos`, accessToken, data, navigate, '/videos'));
  };
  return (
    <>
      <Loading isLoading={loading} />
      <Breadcrumb
        title='New a video'
        textActive='New'
        items={[{ title: 'Videos' }]}
      />
      <div className='card rounded-1 mb-4 p-3'>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form>
                <FieldArray name='videos'>
                  {(fieldArrayProps) => {
                    const { push, remove, form } = fieldArrayProps;
                    const { values } = form;
                    const { videos } = values;
                    return (
                      <div className='row position-relative'>
                        <div className='col-md-7'>
                          <div className='form-group'>
                            <label htmlFor='video' className='text-muted'>
                              Enter your video url{' '}
                              <span className='required'>*</span>
                            </label>
                          </div>
                          {videos?.map((video, index) => (
                            <div className='row mb-1' key={index}>
                              <div className='col-md-11'>
                                <div className='form-group'>
                                  <input
                                    className='form-control'
                                    name={`videos.${index}.video`}
                                    id='video'
                                    onChange={(e) =>
                                      formik.setFieldValue(
                                        `videos.${index}.video`,
                                        e.target.value
                                      )
                                    }
                                    type='url'
                                  />
                                  <ErrorMessage
                                    name={`videos[${index}].video`}
                                    component={TextErrorMessage}
                                  />
                                </div>
                              </div>
                              {index > 0 && (
                                <div className='col-md'>
                                  <div className='form-group'>
                                    <button
                                      type='button'
                                      className='remove-item btn btn-danger mt-2'
                                      onClick={() => remove(index)}
                                    >
                                      <MdRemove />
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>

                        <div className='col-md'>
                          <div className='form-group'>
                            <button
                              type='button'
                              className='add-item btn btn-success'
                              onClick={() => push('')}
                            >
                              <MdAdd />
                            </button>
                          </div>
                        </div>
                        <div className='col-md-12 mt-3 text-center'>
                          <button
                            className='btn btn-primary btn-user btn-inline'
                            type='submit'
                          >
                            <FaLocationArrow style={{ marginRight: '.5rem' }} />
                            New
                          </button>
                        </div>
                      </div>
                    );
                  }}
                </FieldArray>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}
