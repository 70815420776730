import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconStar } from '@tabler/icons';
import { getAllData } from '../../redux/actions/api/GetAllData';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loading from '../../components/loading/Loading';

export default function DataCenter() {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state?.auth);
  const {
    data: dataCenter,
    loading,
    error,
  } = useSelector((state) => state?.api);
  useEffect(() => {
    dispatch(getAllData('data-center', accessToken));
  }, [accessToken, dispatch]);
  return (
    <>
      <Breadcrumb
        url='/data-center/edit'
        title='All information about Data Center'
        textActive='Data Center'
        btnText='Edit'
      />
      {loading ? (
        <Loading isLoading={loading} />
      ) : error ? (
        error
      ) : (
        <div className='container-fluid'>
          <div className='row col-11 col-md-8 m-auto details-info card pe-0'>
            <div className='ribbon ribbon-top bg-yellow'>
              <IconStar />
            </div>
            <div className='row px-0'>
              <div className='col-md-12 py-4 border-bottom border-start border-end'>
                <div className='row px-0'>
                  <div className='col-md-4'>
                    <h3 className='title'>about data center ar :</h3>
                  </div>
                  <div className='col-md-8'>
                    <h4 className='sub-title text-end'>
                      {dataCenter?.data_center_description_ar}
                    </h4>
                  </div>
                </div>
              </div>
              <div className='col-md-12 pt-4 border-start border-end border-bottom '>
                <div className='row'>
                  <div className='col-md-4'>
                    <h3 className='title'>about data center en :</h3>
                  </div>
                  <div className='col-md-8'>
                    <h4 className='sub-title'>
                      {dataCenter?.data_center_description_en}
                    </h4>
                  </div>
                </div>
              </div>
              <div className='col-md-12 pt-4 border-start border-end border-bottom '>
                <div className='row'>
                  <div className='col-md-12'>
                    <h3 className='title'>Showing Image :</h3>
                  </div>
                  <div className='col-md-12'>
                    <img
                      className='rounded-circle'
                      width={100}
                      height={100}
                      src={dataCenter?.data_center_image}
                      alt='ImageCenter'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
