import { useLocation } from 'react-router';
import moment from 'moment';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';

export default function DetailsVirtual() {
  const location = useLocation();
  const {
    id,
    course_category_id,
    video,
    intro,
    title_ar,
    title_en,
    hours,
    fees,
    video_content,
    description_ar,
    description_en,
    created_at,
    updated_at,
  } = location?.state;
  return (
    <>
      <Breadcrumb
        title='Details a virtual'
        textActive='Details'
        items={[{ title: 'Categories' }, { title: 'Virtual' }]}
      />
      {Object.values(location?.state).length > 0 ? (
        <div className='container-fluid'>
          <div className='row col-11 col-md-8 m-auto details-info'>
            <div className='col-6 text-center'>
              {video && (
                <iframe
                  className='mb-3'
                  width='100%'
                  height='240'
                  src={video}
                  title='YouTube video player'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                ></iframe>
              )}
            </div>
            <div className='col-6 text-center'>
              {intro && (
                <iframe
                  className='mb-3'
                  width='100%'
                  height='240'
                  src={intro}
                  title='YouTube video player'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                ></iframe>
              )}
            </div>
            <div className='col-md-6'>
              <div className='row border-bottom border-end border-start'>
                <div className='col-md-4'>
                  <h3 className='title'>id :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>#{id}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='row border-bottom border-end border-start'>
                <div className='col-md-4'>
                  <h3 className='title'>category id :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>#{course_category_id}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-6 pt-4 border-bottom border-end border-start'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>title ar :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>{title_ar}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-6 pt-4 border-bottom border-end border-start border-end-mb-0'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>title en :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>{title_en}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-6 pt-4 border-bottom border-end border-start border-end-mb-0'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>hours :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>{hours}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-6 pt-4 border-bottom border-end border-start'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>fees :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>{fees}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-6 pt-4 border-bottom border-end border-start border-end-mb-0'>
              <div className='row'>
                <div className='col-md-4'></div>
                <div className='col-md-8'></div>
              </div>
            </div>
            <div className='col-md-12 pt-4 border-bottom border-end border-start'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>video content :</h3>
                </div>
                <div className='col-md-8'>
                  <h4
                    className='sub-title text-end'
                    dangerouslySetInnerHTML={{
                      __html: video_content,
                    }}
                  ></h4>
                </div>
              </div>
            </div>
            <div className='col-md-12 pt-4 border-bottom border-end border-start'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>description ar :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title text-end'>{description_ar}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-12 pt-4 border-bottom border-end border-start'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>description en :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>{description_en}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-6 pt-4 border-bottom border-end border-start'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>created at :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>
                    {moment(created_at).format('LLL')}{' '}
                  </h4>
                </div>
              </div>
            </div>
            <div className='col-md-6 pt-4 border-bottom border-end border-start border-end-mb-0'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>updated at :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>
                    {moment(updated_at).format('LLL')}{' '}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='text-error text-center'>
          You have not add any data equals this id products
        </div>
      )}
    </>
  );
}
