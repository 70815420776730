import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FaLocationArrow } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { editData } from '../../redux/actions/api/EditData';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loading from '../../components/loading/Loading';

export default function EditFooter() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.auth);
  const { data: info, loading, error } = useSelector((state) => state.api);
  // ----------------------------------------------------------------------------------->
  useEffect(() => {
    error && toast.error(error);
  }, [dispatch, error, loading]);
  // ----------------------------------------------------------------------------------->
  const initialValues = {
    // initial Values input felids
    phone: info?.phone,
    email: info?.email,
    address: {
      en: info?.address_en,
      ar: info?.address_ar,
    },
    social_media: {
      facebook: info?.social_media?.facebook,
      telegram: info?.social_media?.telegram,
      whatsapp: info?.social_media?.whatsapp,
      linkedin: info?.social_media?.linkedin,
      youtube: info?.social_media?.youtube,
    },
  };
  // ----------------------------------------------------------------------------------->
  const validationSchema = Yup.object({
    // validation and check input is not empty
    email: Yup.string()
      .required(`email required`)
      .min(6, `The email you should not be less than 6 characters`)
      .max(40, `The email you must not than 40 characters`)
      .email('Invalid email format'),
    phone: Yup.string()
      .required(`phone required`)
      .min(10, `The phone you should not be less than 10 characters`)
      .max(25, `The phone you must not than 25 characters`),
    address: Yup.object().shape({
      en: Yup.string()
        .required(`address en required`)
        .min(3, `The address en you should not be less than 3 characters`)
        .max(500, `The address en you must not than 500 characters`),
      ar: Yup.string()
        .required(`address ar required`)
        .min(3, `The address ar you should not be less than 3 characters`)
        .max(500, `The address ar you must not than 500 characters`),
    }),
  });
  // ----------------------------------------------------------------------------------->
  const onSubmit = (values) => {
    // handel to modify footer info
    let data = new FormData();
    data.append('email', values?.email);
    data.append('phone', values?.phone);
    data.append('address_en', values?.address?.en);
    data.append('address_ar', values?.address?.ar);
    data.append('social_media[facebook]', values?.social_media.facebook);
    data.append('social_media[telegram]', values?.social_media.telegram);
    data.append('social_media[whatsapp]', values?.social_media.whatsapp);
    data.append('social_media[linkedin]', values?.social_media.linkedin);
    data.append('social_media[youtube]', values?.social_media.youtube);
    data.append('_method', 'PUT');
    dispatch(
      editData('informations', accessToken, data, navigate, '/footer', true)
    );
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  return (
    <>
      <Loading isLoading={loading} />

      <Breadcrumb
        title="Modify Footer information's"
        textActive='Details'
        items={[{ title: 'footer' }]}
      />
      <div className='card rounded-1 mb-4 p-3'>
        <form onSubmit={formik.handleSubmit}>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label
                  htmlFor='social_media.facebook'
                  className='text-gray-600 small'
                >
                  Enter your facebook
                </label>
                <input
                  className='form-control form-control-user'
                  name='social_media.facebook'
                  placeholder='write social facebook url here....'
                  id='social_media.facebook'
                  {...formik.getFieldProps('social_media.facebook')}
                  type='url'
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label
                  htmlFor='social_media.telegram'
                  className='text-gray-600 small'
                >
                  Enter your telegram
                </label>
                <input
                  className='form-control form-control-user'
                  name='social_media.telegram'
                  placeholder='write social telegram url here....'
                  id='social_media.telegram'
                  {...formik.getFieldProps('social_media.telegram')}
                  type='url'
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label
                  htmlFor='social_media.whatsapp'
                  className='text-gray-600 small'
                >
                  Enter your whatsapp
                </label>
                <input
                  className='form-control form-control-user'
                  name='social_media.whatsapp'
                  placeholder='write social whatsapp url here....'
                  id='social_media.whatsapp'
                  {...formik.getFieldProps('social_media.whatsapp')}
                  type='url'
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label
                  htmlFor='social_media.linkedin'
                  className='text-gray-600 small'
                >
                  Enter your linkedin
                </label>
                <input
                  className='form-control form-control-user'
                  name='social_media.linkedin'
                  placeholder='write social linkedin url here....'
                  id='social_media.linkedin'
                  {...formik.getFieldProps('social_media.linkedin')}
                  type='url'
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label
                  htmlFor='social_media.youtube'
                  className='text-gray-600 small'
                >
                  Enter your youtube
                </label>
                <input
                  className='form-control form-control-user'
                  name='social_media.youtube'
                  placeholder='write social youtube url here....'
                  id='social_media.youtube'
                  {...formik.getFieldProps('social_media.youtube')}
                  type='url'
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='email' className='text-muted'>
                  Enter your email <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='email'
                  id='email'
                  inputMode='email'
                  type='email'
                  placeholder='example@mail.com'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className='text-error'>{formik.errors.email}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='phone' className='text-muted'>
                  Enter your phone <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='phone'
                  id='phone'
                  inputMode='tel'
                  type='tel'
                  placeholder='249123456789'
                  {...formik.getFieldProps('phone')}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className='text-error'>{formik.errors.phone}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'></div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='address_en' className='text-muted'>
                  Enter your address en <span className='required'>*</span>
                </label>
                <textarea
                  className='form-control form-control-user'
                  name='address_en'
                  placeholder='write address en here....'
                  id='address_en'
                  inputMode='text'
                  {...formik.getFieldProps('address.en')}
                  cols='30'
                  rows='5'
                ></textarea>
                {formik.touched?.address?.en && formik.errors?.address?.en ? (
                  <div className='text-error'>{formik.errors?.address?.en}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='address_ar' className='text-muted'>
                  Enter your address ar <span className='required'>*</span>
                </label>
                <textarea
                  className='form-control form-control-user'
                  name='address_ar'
                  placeholder='write address ar here....'
                  id='address_ar'
                  inputMode='text'
                  {...formik.getFieldProps('address.ar')}
                  cols='30'
                  rows='5'
                ></textarea>
                {formik.touched?.address?.ar && formik.errors?.address?.ar ? (
                  <div className='text-error'>{formik.errors?.address?.ar}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-12 mt-3 text-center'>
              <button
                className='btn btn-primary btn-user btn-inline'
                type='submit'
              >
                <FaLocationArrow style={{ marginRight: '.5rem' }} /> Edit Footer
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
