import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isArray } from 'lodash';
import { FaEdit } from 'react-icons/fa';
import { MdOutlineDelete, MdInfoOutline } from 'react-icons/md';
import { getAllData } from '../../redux/actions/api/GetAllData';
import { deleteData } from '../../redux/actions/api/DeleteData';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loading from '../../components/loading/Loading';
import Table from '../../components/table/Table';
import ModalConfirm from '../../components/modal/ModalConfirm';

export default function BooksFiles() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.auth);
  const {
    data: booksFiles,
    loading,
    error,
  } = useSelector((state) => state.api);
  const [id, setId] = useState();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  // ----------------------------------------------------------------------------------->
  const columns = React.useMemo(
    () => [
      {
        Header: 'Image',
        accessor: 'image',
        disableFilters: true,
        Cell: ({ row }) => (
          <img
            width='40'
            height='40'
            className='rounded-circle border'
            src={row.values.image}
            alt='imageBook'
          />
        ),
      },
      {
        Header: 'Title En',
        disableFilters: true,
        accessor: 'title_en',
      },
      {
        Header: 'Title Ar',
        disableFilters: true,
        accessor: 'title_ar',
      },
      {
        Header: 'Created At',
        disableFilters: true,
        accessor: 'created_at',
        Cell: ({ row }) => (
          <time>{moment(row.values.created_at).format('LLL')}</time>
        ),
      },
      {
        Header: 'Updated At',
        disableFilters: true,
        accessor: 'updated_at',
        Cell: ({ row }) => (
          <time>{moment(row.values.updated_at).format('LLL')}</time>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        disableFilters: true,
        Cell: ({ row }) => (
          <>
            <button className='btn btn-icon btn-rounded btn-primary mr-1'>
              <FaEdit
                size='1rem'
                onClick={() => navigate(`/books-files/${row.values.id}/edit`)}
              />
            </button>
            <button className='btn btn-icon btn-rounded btn-danger mr-1'>
              <MdOutlineDelete
                size='1rem'
                onClick={() => {
                  setId(row.values.id);
                  setShowModalConfirm(true);
                }}
              />
            </button>
            <button className='btn btn-icon btn-rounded btn-info'>
              <MdInfoOutline
                size='1rem'
                onClick={() =>
                  navigate(`/books-files/${row.values.id}/details`, {
                    state: row.original,
                  })
                }
              />
            </button>
          </>
        ),
      },
    ],
    [navigate]
  );
  // ----------------------------------------------------------------------------------->
  useEffect(() => {
    dispatch(getAllData('downloadables', accessToken));
  }, [accessToken, dispatch, isShowLoading]);
  // ----------------------------------------------------------------------------------->
  const data = useMemo(() => isArray(booksFiles) && booksFiles, [booksFiles]);
  return (
    <>
      <ModalConfirm
        delete={() =>
          dispatch(
            deleteData(
              `downloadables/${id}`,
              accessToken,
              setShowModalConfirm,
              setIsShowLoading,
              isShowLoading
            )
          )
        }
        showModalConfirmTry={setShowModalConfirm}
        toggleModal={showModalConfirm}
      />
      <Breadcrumb
        url='/books-files/new'
        title='All Books Files'
        textActive='BooksFiles'
        btnText='New'
      />
      {loading ? (
        <Loading isLoading={loading} />
      ) : error ? (
        error
      ) : (
        <div className='card shadow mb-4'>
          {data && <Table columns={columns} data={data ?? []} />}
        </div>
      )}
    </>
  );
}
