import { toast } from 'react-toastify';
import ApiConfig from '../../../api/ApiConfig';
import { DELETE_DATA, ERROR, PRE_REQUEST, REMOVE_COOKIE } from '../../types';

// delete data form api
export const deleteData =
  (
    url,
    accessToken,
    setShowModalConfirm,
    setIsShowLoading,
    isShowLoading,
    imageUrl,
    navigate,
    redirect
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: PRE_REQUEST,
      });
      const res = await ApiConfig.delete(`${url}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          image: imageUrl,
        },
      });
      if (res.status === 200) {
        toast.success('deleted item successfully');
        dispatch({
          type: DELETE_DATA,
        });
        if (redirect) {
          navigate(redirect);
        } else {
          setShowModalConfirm(false);
          setIsShowLoading(!isShowLoading);
        }
      }
    } catch (err) {
      if (redirect) {
        navigate(redirect);
      } else {
        setShowModalConfirm(false);
        setIsShowLoading(!isShowLoading);
      }
      if (err?.response?.status === 401) {
        // status failed userUnauthorized token
        dispatch({ type: REMOVE_COOKIE });
      } else if (err?.response?.status === 422) {
        Object.entries(err?.response?.data?.msg).forEach(([key, value]) => {
          dispatch({
            type: ERROR,
            payload: value[0],
          });
        });
      } else if (err?.response?.status === 500) {
        toast.error('server error');
        dispatch({
          type: ERROR,
          payload: '',
        });
      } else {
        dispatch({
          type: ERROR,
          payload: '',
        });
        toast.error(
          'There is a problem that the remove item was not successful, please try again'
        );
      }
    }
  };
