import React from 'react';

function Loading({ isLoading }) {
  return (
    <div className={`loading-overlay ${isLoading ? 'd-block' : 'd-none'}`}>
      <div className='loader'></div>
    </div>
  );
}

export default React.memo(Loading);
